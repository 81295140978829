$scoot-yellow: #ffe900 !default;
$scoot-yellow-light: #ffe90090;
$scoot-red-error: #da1b25;
$scoot-white-box: #f0f0f0;
$scoot-green-success: #c3e6cb;
$scoot-orange-warning: #ffeeba;

$small-screen: 450px;
$medium-screen: 740px;
$large-screen: 1080px;

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: url('/assets/fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: url('/assets/fonts/Montserrat-Medium.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: url('/assets/fonts/Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: url('/assets/fonts/Montserrat-Bold.ttf');
}
