
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "./styles-variables.scss";

body {
  @apply tw-font-sans tw-font-medium tw-bg-scoot-black-10;
}

h1,
h2,
h3,
h4,
h5 {
  @apply tw-font-bold tw-text-scoot-black-100;
}

input[type="text"]:focus,
input[type="text"]:focus-visible,
input[type="password"]:focus,
input[type="password"]:focus-visible,
input[type="number"]:focus,
input[type="number"]:focus-visible {
  outline: none;
}

@layer components {
  .scoot-card {
    @apply tw-bg-white tw-p-5 lg:tw-p-7.5 tw-border tw-border-solid tw-border-scoot-black-20 tw-rounded-2.5xl;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
  }

  .scoot-button {
    @apply tw-flex tw-items-center tw-justify-center tw-font-semibold tw-leading-4 tw-rounded-2.5xl tw-min-w-[6.25rem];

    &.scoot-button--primary {
      @apply tw-bg-scoot-black-100 tw-text-white tw-px-5 tw-py-3;
    }

    &.scoot-button--secondary {
      @apply tw-border tw-border-scoot-black-100 tw-px-5 tw-py-3;
    }
  }

  .scoot-select {
    @apply tw-text-3 tw-font-medium tw-border-b tw-border-b-scoot-black-100 tw-w-full;

    .ng-value {
      @apply tw-text-scoot-black-100 tw-min-w-min tw-whitespace-normal;
    }

    .ng-placeholder {
      @apply tw-text-scoot-black-40;
    }

    .ng-arrow-wrapper {
      @apply tw-w-6 tw-pr-1;

      .ng-arrow {
        @apply tw-border-solid tw-border-[7px] tw-border-b-2 tw-border-x-transparent tw-border-y-transparent tw-border-t-black;
      }
    }

    .ng-has-value .ng-placeholder {
      @apply tw-hidden;
    }

    &.ng-select-opened {
      @apply tw-border-b-[5px] tw--mb-1;
    }

    .ng-dropdown-panel {
      @apply tw-shadow-lg;
    }

    .ng-dropdown-panel-items {
      @apply tw-max-h-[264px] tw-min-w-min tw-z-0;
      .ng-option {
        @apply tw-p-2.5 tw-bg-white tw-text-scoot-black-100;

        &.ng-option-selected,
        &.ng-option-marked {
          @apply tw-bg-scoot-black-100 tw-text-white;
        }
      }
    }
  }

  .scoot-dropdown {
    @apply tw-bg-white tw-w-64 tw-h-14 tw-rounded-2xl tw-mt-5;
  
    .ng-value {
      @apply tw-text-scoot-black-100 tw-min-w-min tw-whitespace-normal tw-mt-3 tw-ml-6 tw-mb-3;
    }

    .ng-input input {
      display: none;
    }
  
    .ng-placeholder {
      @apply tw-text-scoot-black-40;
    }
  
    .ng-arrow-wrapper {
      @apply tw-w-6 tw-pr-1 tw-mr-6;
  
      .ng-arrow {
        @apply tw-border-solid tw-border-[7px] tw-border-b-2 tw-border-x-transparent tw-border-y-transparent tw-border-t-black tw-mt-6;
      }
    }
  
    .ng-has-value .ng-placeholder {
      @apply tw-hidden;
    }
  
    &.ng-select-opened {
      @apply tw-border-b-[5px] tw--mb-1;
    }
  
    .ng-dropdown-panel {
      @apply tw-shadow-lg;
  
      .ng-dropdown-panel-items {
        @apply tw-max-h-[264px] tw-min-w-min tw-z-0 lg:tw-fixed;
        .ng-option {
          @apply tw-p-2.5 tw-bg-white tw-text-scoot-black-100 tw-w-64;
  
          &.ng-option-selected,
          &.ng-option-marked {
            @apply tw-bg-scoot-black-100 tw-text-white;
          }
        }
      }
    }
  }
}